import React, { useEffect, useState } from 'react';
import { ref, onValue } from "firebase/database"; // Ensure this is imported from your Firebase setup
import { db } from '../firebase'; // Ensure db is your Firebase Realtime Database instance
import { Link } from 'react-router-dom';
import { FaMosque, FaSearch } from 'react-icons/fa'; // Import mosque icon
import logo from "../assets/logo2.png";
import { Helmet } from 'react-helmet';

function Home() {
  const [masajed, setMasajed] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search query

  // Fetch all users (masajed) from Firebase
  useEffect(() => {
    const masajedRef = ref(db, 'users'); // Get the reference to all users
    const unsubscribe = onValue(masajedRef, (snapshot) => {
      if (snapshot.exists()) {
        const masajedData = snapshot.val();
        const masajedArray = Object.keys(masajedData).map((key) => ({
          ...masajedData[key],
          id: key, // Store the unique user ID
        }));
        setMasajed(masajedArray); // Update the state with the fetched masajed
      }
    });

    return () => unsubscribe(); // Cleanup the listener on unmount
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter masajed by search query (msqname)
  const filteredMasajed = masajed.filter((masjed) =>
    masjed?.msqname?.toLowerCase()?.includes(searchQuery?.toLowerCase()) // Filter by msqname
  );

  return (
    <div className='Home'>
      <Helmet>
          <title>ساعة منارة الرقمية للمساجد</title>
      </Helmet>
      <nav>
        <img src={logo} alt="Logo" />
        <div>
          <a href='tel:+966554344899'>الاشتراك</a>
          <Link to="/client/login">تسجيل دخول</Link>
        </div>
      </nav>

      <section className='Hero'>
        <h1>ساعة منارة الرقمية للمساجد</h1>
        <h2>أفضل ساعة لمساجدكم لضبط أوقات الصلاة</h2>
      </section>

      <section className='Masajed'>
        <div>
          <h1>ابحث عن مسجدك</h1>
          {/* Search input */}
          <div className='Search'>
            <input
              type='text'
              placeholder='ابحث عن المسجد'
              value={searchQuery}
              onChange={handleSearch}
            />
            <FaSearch />
          </div>
          {/* All Masajed Holder */}
          <div className='masajedHolder'>
            {filteredMasajed.length > 0 ? (
              filteredMasajed.slice(0,8).map((masjed) => {
                const emailUsername = masjed.email.split('@')[0]; // Get the part before '@' from email

                return (
                  <Link to={`/masjid/${emailUsername}`} key={masjed.id} className='masjed-card'>
                    <div>
                      <FaMosque size={40} /> {/* Mosque Icon */}
                      <h3>{masjed.msqname}</h3> {/* Masjed name in Arabic */}
                    </div>
                  </Link>
                );
              })
            ) : (
              <p>لا توجد نتائج</p> // Display when no masajed match the search query
            )}
          </div>
        </div>
      </section>

      <section className='AboutTheClock'>
          <div>
            <img src={logo}></img>
            <p>ساعة منارة الرقمية للمساجد تعرض أوقات الصلاة، الإقامة، التاريخ والوقت بشكل عصري ومخصص لكل مسجد. يمكن عرضها على أي تلفاز بحجم مناسب، ويمكن لجماعة المسجد متابعة المسجد عبر هواتفهم أو التلفاز الذكي باستخدام الرابط المخصص</p>
            <span>
              <a href='tel:+966554344899'>اطلبها الآن</a>
              <Link to="/client/login">تسجيل دخول</Link>
            </span>
          </div>
      </section>

      <section className='AboutTheCreator'>
        <div>
          <h1>تم ابتكار هذا المنتج من قبل <span>سلالم الإبداع</span></h1>
          <p>سلالم الإبداع، مؤسسة سعودية في جدة تقدم حلول مبتكرة في التكنولوجيا الناشئة، المعارض، التأثيث، الإعلام، وتكنولوجيا المعلومات. نعتبر أنفسنا "مختبر ابتكار" لدفع حدود التكنولوجيا ودعم السوق المحلي بحلول متقدمة.</p>
          <a href='https://www.innovationladders.com/'>استكشف منتجاتنا</a>
        </div>
      </section>
      <footer>
        <div>
          <img src={logo}></img>
          <label>© 2024 Innovation Ladders - Jeddah - Saudi Arabia</label>
        </div>
      </footer>
    </div>
  );
}

export default Home;
